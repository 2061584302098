import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import NonMobile from "../../components/NonMobile/NonMobile";
import ChallengeIcon from "../../assets/images/icons/ChallengeIcon";
import EventIcon from "../../assets/images/icons/EventIcon";
import JobIcon from "../../assets/images/icons/JobsIcon";
import DashboardIcon from "../../assets/images/icons/DashboardIcon";
import ChatIcon from "../../assets/images/icons/ChatIcon";
import UserIcon from "../../assets/images/icons/UserIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
const Third = ({ packages }) => {
  const { isNonMobile1024, isNonMobile1440, isNonMobile768, isNonMobile500 } =
    NonMobile();
  const [closed, setClosed] = useState([]);

  const contentRefs = useRef([]);

  const Entries = {
    Challenges: [
      ["Insights Challenge", "2", "4", "Unlimited"],
      ["Virtual Experience Challenge", "-", "4", "Unlimited"],
      ["Fast-Track Challenge", "-", "2", "4"],
      ["Branded Certificate", "✔", "✔", "✔"],
      ["Student Rankings", "2", "2", "2"],
      ["Student Analytics", "2", "Limited", "Advanced"],
      ["Automatic Message", "2", "Template", "Custom"],
    ],
    Events: [
      ["Publish Physical Events", "Unlimited", "Unlimited", "Unlimited"],
      ["Varyance Live Stream", "-", "2", "6"],
      ["Social Share", "-", "✔", "✔"],
      ["Ask Us", "-", "For Live Streams", "For All Event Types"],
      ["Automatic Message ", "-", "Template", "Custom"],
      ["Student Analytics", "-", "Limited", "Advanced"],
      ["Attach Job Posts", "✔", "✔", "✔"],
      ["Attach Files", "-", "✔", "✔"],
      ["Attach Challenges", "-", "-", "✔"],
    ],
    "Job Posts": [
      ["Publish Jobs", "Unlimited", "Unlimited", "Unlimited"],
      ["Smart Apply", "-", "✔", "✔"],
      ["Fast-Track Applications", "-", "✔", "✔"],
      ["Link Challenge", "-", "✔", "✔"],
      ["AAI Interviews", "-", "-", "✔"],
      ["ATS Integration", "-", "-", "✔"],
    ],
    "Talent Pool": [
      ["Touchpoint Analysis", "Limited", "Unlimited", "Unlimited"],
      ["Student Profiles", "-", "Limited", "Unlimited"],
      ["Shortlist", "-", "200", "Unlimited"],
      ["Target Schools", "-", "8", "20"],
      ["Engagement Analysis", "-", "✔", "✔"],
      ["Bulk Messages", "-", "200", "Unlimited"],
    ],
    Messenger: [
      ["Custom Message", "-", "✔", "✔"],
      ["Bulk Message", "-", "✔", "✔"],
      ["Variables", "-", "✔", "✔"],
      ["Attach Challenges", "-", "✔", "✔"],
      ["Attach Events/Jobs", "-", "✔", "✔"],
      ["Job Closing Notifications", "-", "-", "✔"],
      ["Auto-reply Templates", "-", "-", "✔"],
      ["Smart-Reply", "-", "-", "✔"],
    ],
    Support: [
      ["Onboarding Support", "✔", "✔", "✔"],
      ["Access to Help Center", "✔", "✔", "✔"],
      ["Priority Support", "-", "✔", "✔"],
      ["Dedicated Account Manager", "-", "-", "✔"],
    ],
  };

  const Addons = {
    "Add-ons": [
      ["Advanced Company Brand Pages", "-", "✔", "✔"],
      ["Campus Ambassadors", "-", "✔", "✔"],
      ["Social Feed Ads", "-", "-", "✔"],
      ["Multi-School Career Fair Participation ", "-", "-", "✔"],
    ],
  };
  useEffect(() => {
    Object.entries(Entries).forEach((_, index) => {
      if (!contentRefs.current[index]) {
        contentRefs.current[index] = React.createRef();
      }
    });
  }, [Entries]);

  const showData = (Entries, text) => {
    return (
      <div className="d-flex flex-column" style={{}}>
        {Object.entries(Entries).map(
          ([section, row], index, array) =>
            (text
              ? text === "first"
                ? index !== array.length - 1
                : index === array.length - 1
              : true) && (
              <div
                key={index}
                className="transition"
                style={{
                  marginTop:
                    text === "last" || text === "support" ? "80px" : "",
                }}
              >
                <div
                  className=" align-items-center space"
                  style={{
                    padding: isNonMobile1440 ? "12px 24px" : "12px 20px",
                    border: "1px solid #EAECF0",
                    background: "#F2F4F7",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (closed.includes(index)) {
                      setClosed(closed.filter((item) => item !== index));
                    } else {
                      setClosed([...closed, index]);
                    }
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "0.75rem" }}
                  >
                    {index === 0 &&
                      (text === "support" ? (
                        <AddBoxOutlinedIcon
                          style={{
                            height: "20px",
                            width: "20px",
                            color: "#416BFF",
                          }}
                        />
                      ) : (
                        <ChallengeIcon />
                      ))}
                    {index === 1 && <EventIcon color={"#FAA24B"} />}
                    {index === 2 && <JobIcon color={"#383874"} />}
                    {index === 3 && <DashboardIcon color={"#079455"} />}
                    {index === 4 && <ChatIcon />}
                    {index === 5 && <UserIcon />}
                    <p className="gray90018500">{section}</p>
                  </div>
                  {closed.includes(index) ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </div>
                <div
                  ref={contentRefs.current[index]}
                  style={{
                    height: closed.includes(index)
                      ? "0px"
                      : `${contentRefs.current[index]?.current?.scrollHeight}px`,
                    opacity: closed.includes(index) ? "0" : "1",

                    overflow: "hidden",
                  }}
                >
                  <table className="pricing-table">
                    <tbody>
                      {row.map((ro, i) => (
                        <React.Fragment key={ro}>
                          <tr>
                            <td
                              style={{
                                width: "100%",
                                fontSize: "18px",
                                lineHeight: "24px",
                              }}
                              className="gray70014400  "
                            >
                              <div className=" d-flex gap-2  ">
                                <p
                                  className="gray70014400"
                                  style={{
                                    fontSize: "18px",
                                    width: "fit-content",
                                    lineHeight: "24px",
                                  }}
                                >
                                  {ro[0]}
                                </p>
                                {(ro[0] === "Fast-Track Challenge" ||
                                  ro[0] === "Fast-Track Applications" ||
                                  ro[0] === "AAI Interviews") && (
                                  <p
                                    className="white12400 d-flex"
                                    style={{
                                      padding: "2px 12px",
                                      borderRadius: "32px",
                                      fontWeight: 500,
                                      minWidth: "100px",
                                      maxHeight: "24px",
                                      background:
                                        "var(--Red-Liniar, linear-gradient(136deg, #C01048 -4.38%, #A11043 67.26%, #89123E 102.63%))",
                                    }}
                                  >
                                    AI Evaluated{" "}
                                  </p>
                                )}
                                {ro[0] === "Smart-Reply" && (
                                  <p
                                    className="white12400 d-flex"
                                    style={{
                                      padding: "2px 12px",
                                      borderRadius: "32px",
                                      fontWeight: 500,
                                      minWidth: "85px",
                                      maxHeight: "24px",
                                      background:
                                        "var(--Red-Liniar, linear-gradient(136deg, #C01048 -4.38%, #A11043 67.26%, #89123E 102.63%))",
                                    }}
                                  >
                                    AI Feature
                                  </p>
                                )}
                              </div>
                            </td>
                            <td
                              className="gray60016400 text-center"
                              style={{
                                width: isNonMobile1440 ? "255px" : "185px",
                                fontSize: "18px",
                                lineHeight: "24px",
                                minWidth: isNonMobile1440 ? "255px" : "185px",
                                maxWidth: isNonMobile1440 ? "255px" : "185px",
                                borderLeft: "1px solid #eaecf0",
                              }}
                            >
                              {ro[1]}
                            </td>
                            <td
                              className="gray60016400 text-center"
                              style={{
                                width: isNonMobile1440 ? "255px" : "185px",
                                fontSize: "18px",
                                lineHeight: "24px",
                                minWidth: isNonMobile1440 ? "255px" : "185px",
                                maxWidth: isNonMobile1440 ? "255px" : "185px",
                                borderLeft: "1px solid #eaecf0",
                              }}
                            >
                              {ro[2]}
                            </td>
                            <td
                              className="gray60016400 text-center"
                              style={{
                                width: isNonMobile1440 ? "255px" : "185px",
                                fontSize: "18px",
                                lineHeight: "24px",
                                minWidth: isNonMobile1440 ? "255px" : "185px",
                                maxWidth: isNonMobile1440 ? "255px" : "185px",
                                borderLeft: "1px solid #eaecf0",
                              }}
                            >
                              {ro[3]}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )
        )}
      </div>
    );
  };

  return (
    <div>
      <div id="compare" style={{ marginBottom: "6.25rem", background: "#FFF" }}>
        <div className="marginsleftrightCompany">
          <div className="center">
            <div className="headerCompany" style={{ position: "relative" }}>
              <div
                className="d-flex justify-content-end"
                style={{
                  width: "100%",
                  position: "sticky",

                  zIndex: 9999,
                  top: 0,
                  borderBottom: "1px solid #EAECF0",

                  // background: "#FFF",
                }}
              >
                <div
                  className=""
                  style={{ width: "100%", background: "#FFF" }}
                ></div>
                {packages.map((item, index) => (
                  <div
                    className="space flex-column gap-3"
                    key={index}
                    style={{
                      maxWidth: isNonMobile1440
                        ? index === 0
                          ? "256px"
                          : "255px"
                        : index === 0
                        ? "186px"
                        : "185px",

                      width: "100%",
                      height: "280px",
                      padding: isNonMobile1440 ? "20px 25px" : "12px",
                      background: "rgba(255,255,255,0.5)",
                      backdropFilter: "blur(5px)",
                      border: "1px solid #EAECF0",
                      borderLeft: index === 0 ? "1px solid #EAECF0" : "none",
                      borderBottom: "none",
                    }}
                  >
                    <div>
                      <img
                        src={item.logo}
                        alt="logo"
                        style={{ height: "36px", width: "36px" }}
                        className="shadowa rounded-1"
                      />
                      <h4 style={{ margin: "1.875rem 0rem" }}>{item.title}</h4>
                      <p className="gray70016400">{item.desc4}</p>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex">
                        <Link
                          to={item.buttonlink}
                          style={{ textDecoration: "none" }}
                        >
                          <button
                            className="continuebutton"
                            style={{
                              background:
                                item.buttonname === "Start Trial" && "none",
                              border:
                                item.buttonname === "Start Trial" &&
                                "1px solid #E31b54",
                              color:
                                item.buttonname === "Start Trial" && "#E31b54",
                            }}
                          >
                            {item.buttonname}
                          </button>
                        </Link>
                      </div>
                      {/* <a href="#compare" className="pink14">
                      Compare features.
                    </a> */}
                    </div>
                  </div>
                ))}
              </div>
              {showData(Entries, "first")}
            </div>
          </div>
          <div className="center">
            <div className="headerCompany" style={{ position: "relative" }}>
              {showData(Entries, "last")}
              <div>{showData(Addons, "support")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Third;
