const DashboardIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91199 2.34863H7.47363C8.53499 2.34863 9.39538 3.1881 9.39538 4.22363V6.03197C9.39538 7.0675 8.53499 7.90697 7.47363 7.90697H3.91199C2.85063 7.90697 1.99023 7.0675 1.99023 6.03197V4.22363C1.99023 3.1881 2.85063 2.34863 3.91199 2.34863ZM7.47363 6.65697C7.82549 6.65248 8.10961 6.37527 8.11421 6.03197V4.22363C8.10961 3.88033 7.82549 3.60312 7.47363 3.59863H3.91199C3.56012 3.60312 3.276 3.88033 3.2714 4.22363V6.03197C3.276 6.37527 3.56012 6.65248 3.91199 6.65697H7.47363Z"
        fill={color ? color : "#E31B54"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5976 2.34863H16.587C17.6483 2.34863 18.5087 3.1881 18.5087 4.22363V9.9153C18.5087 10.9508 17.6483 11.7903 16.587 11.7903H13.5976C13.0872 11.7925 12.5971 11.5957 12.2362 11.2436C11.8753 10.8915 11.6736 10.4133 11.6758 9.9153V4.22363C11.6758 3.1881 12.5362 2.34863 13.5976 2.34863ZM16.587 10.5403C16.9408 10.5403 17.2276 10.2605 17.2276 9.9153V4.22363C17.223 3.88033 16.9388 3.60312 16.587 3.59863H13.5976C13.2438 3.59863 12.957 3.87846 12.957 4.22363V9.9153C12.9547 10.0817 13.0214 10.242 13.1421 10.3597C13.2627 10.4774 13.427 10.5426 13.5976 10.5403H16.587Z"
        fill={color ? color : "#E31B54"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5976 14.0153H16.587C17.6484 14.0153 18.5088 14.8548 18.5088 15.8903V17.1403C18.5088 18.1758 17.6484 19.0153 16.587 19.0153H13.5976C12.5363 19.0153 11.6759 18.1758 11.6759 17.1403V15.8903C11.6759 14.8548 12.5363 14.0153 13.5976 14.0153ZM16.587 17.7653C16.9389 17.7608 17.223 17.4836 17.2276 17.1403V15.8903C17.223 15.547 16.9389 15.2698 16.587 15.2653H13.5976C13.2438 15.2653 12.957 15.5451 12.957 15.8903V17.1403C12.957 17.4855 13.2438 17.7653 13.5976 17.7653H16.587Z"
        fill={color ? color : "#E31B54"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91199 10.1236H7.47363C8.53499 10.1236 9.39538 10.9631 9.39538 11.9986V17.1403C9.39538 18.1758 8.53499 19.0153 7.47363 19.0153H3.91199C2.85063 19.0153 1.99023 18.1758 1.99023 17.1403V11.9986C1.99023 10.9631 2.85063 10.1236 3.91199 10.1236ZM7.47363 17.7653C7.82549 17.7608 8.10961 17.4836 8.11421 17.1403V11.9986C8.10961 11.6553 7.82549 11.3781 7.47363 11.3736H3.91199C3.56012 11.3781 3.276 11.6553 3.2714 11.9986V17.1403C3.276 17.4836 3.56012 17.7608 3.91199 17.7653H7.47363Z"
        fill={color ? color : "#E31B54"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91199 2.34863H7.47363C8.53499 2.34863 9.39538 3.1881 9.39538 4.22363V6.03197C9.39538 7.0675 8.53499 7.90697 7.47363 7.90697H3.91199C2.85063 7.90697 1.99023 7.0675 1.99023 6.03197V4.22363C1.99023 3.1881 2.85063 2.34863 3.91199 2.34863ZM7.47363 6.65697C7.82549 6.65248 8.10961 6.37527 8.11421 6.03197V4.22363C8.10961 3.88033 7.82549 3.60312 7.47363 3.59863H3.91199C3.56012 3.60312 3.276 3.88033 3.2714 4.22363V6.03197C3.276 6.37527 3.56012 6.65248 3.91199 6.65697H7.47363Z"
        stroke={color ? color : "#E31B54"}
        strokeWidth="0.634187"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5976 2.34863H16.587C17.6483 2.34863 18.5087 3.1881 18.5087 4.22363V9.9153C18.5087 10.9508 17.6483 11.7903 16.587 11.7903H13.5976C13.0872 11.7925 12.5971 11.5957 12.2362 11.2436C11.8753 10.8915 11.6736 10.4133 11.6758 9.9153V4.22363C11.6758 3.1881 12.5362 2.34863 13.5976 2.34863ZM16.587 10.5403C16.9408 10.5403 17.2276 10.2605 17.2276 9.9153V4.22363C17.223 3.88033 16.9388 3.60312 16.587 3.59863H13.5976C13.2438 3.59863 12.957 3.87846 12.957 4.22363V9.9153C12.9547 10.0817 13.0214 10.242 13.1421 10.3597C13.2627 10.4774 13.427 10.5426 13.5976 10.5403H16.587Z"
        stroke={color ? color : "#E31B54"}
        strokeWidth="0.634187"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5976 14.0153H16.587C17.6484 14.0153 18.5088 14.8548 18.5088 15.8903V17.1403C18.5088 18.1758 17.6484 19.0153 16.587 19.0153H13.5976C12.5363 19.0153 11.6759 18.1758 11.6759 17.1403V15.8903C11.6759 14.8548 12.5363 14.0153 13.5976 14.0153ZM16.587 17.7653C16.9389 17.7608 17.223 17.4836 17.2276 17.1403V15.8903C17.223 15.547 16.9389 15.2698 16.587 15.2653H13.5976C13.2438 15.2653 12.957 15.5451 12.957 15.8903V17.1403C12.957 17.4855 13.2438 17.7653 13.5976 17.7653H16.587Z"
        stroke={color ? color : "#E31B54"}
        strokeWidth="0.634187"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91199 10.1236H7.47363C8.53499 10.1236 9.39538 10.9631 9.39538 11.9986V17.1403C9.39538 18.1758 8.53499 19.0153 7.47363 19.0153H3.91199C2.85063 19.0153 1.99023 18.1758 1.99023 17.1403V11.9986C1.99023 10.9631 2.85063 10.1236 3.91199 10.1236ZM7.47363 17.7653C7.82549 17.7608 8.10961 17.4836 8.11421 17.1403V11.9986C8.10961 11.6553 7.82549 11.3781 7.47363 11.3736H3.91199C3.56012 11.3781 3.276 11.6553 3.2714 11.9986V17.1403C3.276 17.4836 3.56012 17.7608 3.91199 17.7653H7.47363Z"
        stroke={color ? color : "#E31B54"}
        strokeWidth="0.634187"
      />
    </svg>
  );
};
export default DashboardIcon;
