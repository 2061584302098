const UserIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3159 13.9249L16.5159 14.8749C16.7054 15.6962 16.5182 16.5592 16.0056 17.2283C15.4929 17.8974 14.7082 18.3026 13.8659 18.3332H6.13252C5.29016 18.3026 4.50549 17.8974 3.99281 17.2283C3.48012 16.5592 3.29299 15.6962 3.48252 14.8749L3.68252 13.9249C3.91255 12.6388 5.01804 11.6938 6.32418 11.6665H13.6742C14.9803 11.6938 16.0858 12.6388 16.3159 13.9249ZM13.8659 17.0749C14.289 17.07 14.6872 16.8739 14.9492 16.5415V16.5499C15.2706 16.1467 15.3958 15.6213 15.2909 15.1165L15.0909 14.1665C14.9799 13.4625 14.3864 12.9353 13.6742 12.9082H6.32419C5.61199 12.9353 5.01849 13.4625 4.90752 14.1665L4.70752 15.1165C4.60515 15.6187 4.73024 16.1404 5.04919 16.5415C5.31113 16.8739 5.70936 17.07 6.13252 17.0749H13.8659Z"
        fill={color ? color : "#079455"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4158 9.99985H9.5825C7.74155 9.99985 6.24917 8.50747 6.24917 6.66652V4.46652C6.24695 3.72323 6.54123 3.00975 7.06682 2.48416C7.5924 1.95858 8.30589 1.66429 9.04917 1.66652H10.9492C11.6925 1.66429 12.4059 1.95858 12.9315 2.48416C13.4571 3.00975 13.7514 3.72323 13.7492 4.46652V6.66652C13.7492 8.50747 12.2568 9.99985 10.4158 9.99985ZM9.04917 2.91652C8.19313 2.91652 7.49917 3.61048 7.49917 4.46652V6.66652C7.49917 7.81711 8.43191 8.74985 9.5825 8.74985H10.4158C11.5664 8.74985 12.4992 7.81711 12.4992 6.66652V4.46652C12.4992 4.05543 12.3359 3.66118 12.0452 3.3705C11.7545 3.07982 11.3603 2.91652 10.9492 2.91652H9.04917Z"
        fill={color ? color : "#079455"}
      />
    </svg>
  );
};
export default UserIcon;
