import { useEffect, useState } from "react";
import NewComp from "../Home/NewComp";
import First from "./First";
import axios from "axios";
import Second from "./Second";
import MarqueSection from "../Home/Marquee";
import HomeFooter from "../../components/Home/HomeFooter";

const MagazineMain = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [english, setEnglish] = useState(true);
  const [selectedUni, setSelectedUni] = useState("");
  const getBlogs = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/get/magazine/blogs`)
      .then((res) => {
        setBlogs(res.data.blogs);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBlogs();
    const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

    if (userLocale.startsWith("de")) {
      setEnglish(false);
    }
  }, []);
  return (
    <NewComp>
      <First loading={loading} blogs={blogs} english={english} />
      <Second loading={loading} blogs={blogs} english={english} />
      <div style={{ background: "rgba(242, 244, 247, 0.80)" }}>
        <MarqueSection
          selectedUni={selectedUni}
          setSelectedUni={setSelectedUni}
        />
        <div className="" style={{ background: "#0C111D" }}>
          <div className="marginsleftrightCompany">
            <div className="center">
              <div className="headerCompany">
                <HomeFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewComp>
  );
};
export default MagazineMain;
