import { useEffect, useRef, useState } from "react";

import SchoolIcon from "@mui/icons-material/School";

import { useMediaQuery } from "@mui/material";
import { FixedSizeList as List } from "react-window";

import { setUni } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import HomeThirdSection from "../../components/Home/HomeThirdSection";

const UniSelect = ({
  button = true,
  setSelectedUni,
  selectedUni,
  Section = false,
  fullwidth = false,
  noclicks = false,
  dontSelect = false,
  pulsate = false,
}) => {
  const isNonMobile450 = useMediaQuery("(min-width:450px)");

  const isNonMobile1024 = useMediaQuery("(min-width:1025px)");
  const isNonMobile1440 = useMediaQuery("(min-width:1440px)");
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:768px)");

  const [unilist, setUniList] = useState([]);
  const [country, setCountry] = useState("");
  const [checker, setChecker] = useState(false);
  const [quickselects, setQuickSelects] = useState([]);
  const Uni = useSelector((state) => state.Uni);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (Uni && !dontSelect) {
      setSelectedUni(Uni);
    }
  }, [Uni]);

  const [changeLocation, ShowChangeLocation] = useState(country ? false : true);

  const Countries = [
    {
      icon: "https://varyance.b-cdn.net/uploads/France.png",
      name: "France",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Germany.png",
      name: "Germany",
    },
    {
      icon: "https://varyance.b-cdn.net/uploads/United%20Kingdom.png",
      name: "United Kingdom",
    },
    {
      icon: "https://varyance.b-cdn.net/uploads/Switzerland.png",
      name: "Switzerland",
    },
    {
      icon: "https://varyance.b-cdn.net/uploads/Estonia.png",
      name: "Estonia",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Finland.png",
      name: "Finland",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Georgia.png",
      name: "Georgia",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Greece.png",
      name: "Greece",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Hungary.png",
      name: "Hungary",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Ireland.png",
      name: "Ireland",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Italy.png",
      name: "Italy",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Latvia.png",
      name: "Latvia",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Liechtenstein.png",
      name: "Liechtenstein",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Lithuania.png",
      name: "Lithuania",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Luxembourg.png",
      name: "Luxembourg",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Malta.png",
      name: "Malta",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Moldova.png",
      name: "Moldova",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Netherlands.png",
      name: "Netherlands",
    },
    {
      icon: "https://varyance.b-cdn.net/uploads/North%20Macedonia.png",
      name: "North Macedonia",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Norway.png",
      name: "Norway",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Poland.png",
      name: "Poland",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Portugal.png",
      name: "Portugal",
    },
    {
      icon: "https://varyance.b-cdn.net/uploads/Romania.png",
      name: "Romania",
    },
    {
      icon: "https://varyance.b-cdn.net/uploads/Slovakia.png",
      name: "Slovakia",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Slovenia.png",
      name: "Slovenia",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Spain.png",
      name: "Spain",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Sweden.png",
      name: "Sweden",
    },

    {
      icon: "https://varyance.b-cdn.net/uploads/Turkey.png",
      name: "Turkey",
    },
  ];

  const [showUni, setShowUni] = useState(false);
  const [search, setSearch] = useState("");
  const unilistinputdivRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        unilistinputdivRef.current &&
        !unilistinputdivRef.current.contains(event.target)
      ) {
        setShowUni(false); // Close showUni when clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUniClick = () => {
    if (!noclicks) setShowUni(!showUni);
  };
  const [searchList, setSearchList] = useState(unilist);
  useEffect(() => {
    setSearchList(unilist);
  }, [unilist]);

  const [countrySearchList, SetCountriesList] = useState(Countries);
  const filterCountries = () => {
    if (!search) {
      SetCountriesList(Countries); // Reset searchList if search input is empty
    } else {
      const filteredUniversities = Countries.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      SetCountriesList(filteredUniversities);
    }
  };

  // Update searchList whenever search state changes
  useEffect(() => {
    filterCountries();
  }, [search]);

  const filterUniversities = () => {
    if (!search) {
      setSearchList(unilist); // Reset searchList if search input is empty
    } else {
      const filteredUniversities = unilist.filter((uni) =>
        uni.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchList(filteredUniversities);
    }
  };

  useEffect(() => {
    filterUniversities();
  }, [search]);

  const isValidImage = async (base64String) => {
    try {
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = () => reject();
        img.src = `${base64String}`;
      });
      return true; // Image loaded successfully
    } catch (error) {
      return false; // Error occurred while loading image
    }
  };

  const ValidateLogo = async (base64String) => {
    return await isValidImage(base64String);
  };

  const UniversityLogo = ({ logo }) => {
    const [imageType, setImageType] = useState("image"); // "image" or "svg"
    const [content, setContent] = useState(logo); // For image or parsed SVG/XML
    const defaultLogo = "/path/to/default/logo.png"; // Replace with your fallback logo

    useEffect(() => {
      if (logo) {
        validateAndLoadResource(logo);
      }
    }, [logo]);

    const validateAndLoadResource = async (url) => {
      try {
        const response = await fetch(url);

        if (!response.ok) throw new Error("Failed to fetch resource");

        const contentType = response.headers.get("Content-Type");

        if (contentType.includes("image")) {
          // For standard images (JPEG, PNG, etc.)
          setImageType("image");
          setContent(url);
        } else if (contentType.includes("xml") || contentType.includes("svg")) {
          // For SVG or XML content
          setImageType("svg");
          const textContent = await response.text();
          setContent(textContent);
        } else {
          throw new Error("Unsupported content type");
        }
      } catch (error) {
        console.error("Error handling resource:", error);
        setImageType("image"); // Default to image fallback
        setContent(defaultLogo);
      }
    };

    if (imageType === "image") {
      return (
        <img
          src={content}
          alt="University Logo"
          style={{ width: "24px", height: "24px", objectFit: "contain" }}
          className="Interest_img_icon shadowa rounded-5"
          onError={() => setContent(defaultLogo)}
        />
      );
    }

    if (imageType === "svg") {
      return (
        <div
          className="Interest_img_icon shadowa rounded-5"
          style={{
            width: "24px",
            height: "24px",
            display: "inline-block",
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    }

    return null; // Handle cases where content isn't available
  };

  const renderUniversityItem = ({ index, style }) => {
    let uni;

    if (quickselects.length > 0 && !search) {
      if (index === 0) {
        // Special case to show 'QUICKSELECT' at the start
        return (
          <div
            key={index}
            className="d-flex unilists"
            style={{
              ...style,
              gap: "0.5rem",
              width: "97%",
              height: "fit-content",
              alignItems: "center",
              marginLeft: "0.5rem",
            }}
          >
            <p className="gray40014500">QUICKSELECT</p>
          </div>
        );
      }

      if (index < quickselects.length && !search) {
        // Access universities in the quickselects array
        uni = quickselects[index - 1];
      } else if (index === quickselects.length) {
        // Show 'MORE UNIVERSITIES' after quickselects
        return (
          <div
            key={index}
            className="d-flex unilists"
            style={{
              ...style,
              gap: "0.5rem",
              width: "97%",
              height: "fit-content",
              alignItems: "center",
              marginLeft: "0.5rem",
            }}
          >
            <p className="gray40014500">MORE UNIVERSITIES</p>
          </div>
        );
      } else if (!search) {
        // Access remaining universities from searchList
        uni = searchList[index - quickselects.length - 1];
      }
    } else {
      // If no quickselects, just use the searchList
      uni = searchList[index];
    }
    if (!uni && !uni?.name) {
      return null;
    }

    return (
      <div key={index}>
        <div
          className="d-flex unilists"
          style={{
            ...style,
            gap: "0.5rem",
            width: "97%",
            height: "fit-content",
            alignItems: "center",
            marginLeft: "0.5rem",
          }}
          onClick={() => {
            setSelectedUni(uni);
            setShowUni(false);
            dispatch(setUni({ Uni: uni }));
          }}
        >
          {uni?.logo && ValidateLogo(uni?.logo) ? (
            <img
              src={uni?.logo}
              alt="University Logo"
              style={{ width: "24px", height: "24px", objectFit: "contain" }}
              className="Interest_img_icon shadowa rounded-5"
              onError={(e) =>
                (e.target.src =
                  "https://varyance.b-cdn.net/uploads/uniicon.png")
              }
            />
          ) : (
            <img
              src={"https://varyance.b-cdn.net/uploads/uniicon.png"}
              alt="University Logo"
              style={{
                width: "24px",
                height: "24px",
                objectFit: "contain",
              }}
              className="Interest_img_icon shadowa rounded-5"
            />
          )}

          {uni?.name && (
            <p
              className="gray90014400 d-flex"
              style={{ alignItems: "center", fontWeight: 500 }}
            >
              {truncateString(uni?.name, 50)}
            </p>
          )}
        </div>
      </div>
    );
  };

  const renderUniversityList = () => (
    <List
      height={250}
      itemCount={
        searchList.length +
        (search ? 0 : quickselects.length > 0 ? quickselects.length + 2 : 0)
      }
      itemSize={isNonMobile ? 55 : 55}
      width={"100%"}
      className="overflowx whitescrollbar"
    >
      {renderUniversityItem}
    </List>
  );

  useEffect(() => {
    const fetchCountry = async () => {
      if (!Uni) {
        try {
          const response = await fetch(
            "https://api.ipgeolocation.io/ipgeo?apiKey=937ed3feac56473db26d79a76bccb671"
          );
          const data = await response.json();

          const userCountry = data.country_name || "Unknown";

          const matchingCountry = Countries.find(
            (country) =>
              country.name.toLowerCase() === userCountry.toLowerCase()
          );

          if (matchingCountry) {
            setCountry(matchingCountry);
            setChecker(true);
          } else {
            setCountry(null);
          }
        } catch (error) {
          console.error("Error fetching country:", error.message);
        }
      } else {
        const matchingCountry = Countries.find(
          (country) => country?.name === Uni?.area
        );

        if (matchingCountry) {
          setCountry(matchingCountry);
          ShowChangeLocation(false);
        }
      }
    };
    if (!dontSelect) {
      fetchCountry();
    }
  }, []);

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  const quicks = [
    {
      name: "Germany",
      unis: [
        "Frankfurt School of Finance & Management",
        "Universität Hamburg",
        "Goethe-Universität Frankfurt am Main",
        "Technische Universität München - TUM",
        "EBS Universität für Wirtschaft und Recht",
        "Universität Mannheim",
        "International School of Management",
      ],
    },
  ];

  const HandleQuickSelects = async (unis, countryname) => {
    if (unis.length > 0) {
      let quickones;
      const country = quicks.find((quick) => quick.name === countryname);
      if (country) {
        // Log country data

        quickones = country.unis
          .map((uni) => {
            // Log the current university
            const foundUni = unis.find(
              (un) => un.name.toLowerCase().trim() === uni.toLowerCase().trim()
            );
            // Log the found university
            return foundUni ? foundUni : false;
          })
          .filter(Boolean); // Filter out undefined values

        setQuickSelects(quickones); // Set the filtered universities
      } else {
        setQuickSelects([]); // Set empty if no matching country
      }
    } else {
      setQuickSelects([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (country) {
        setLoading(true);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/get/university`, {
            name: country.name,
          })
          .then((res) => {
            if (res.data.msg === "success") {
              setUniList(res.data.university);
              HandleQuickSelects(res.data.university, country.name);
              setLoading(false);
            } else {
              console.log(res.data);
            }
          })
          .catch((err) => {
            setLoading(false);
          });

        setLoading(false);
      }
    };
    fetchData();
  }, [country]);

  if (Section) {
    return (
      <div className="marginsleftrightCompany ">
        <HomeThirdSection
          unilist={unilist}
          selectedUni={selectedUni}
          setSelectedUni={setSelectedUni}
          country={country}
          setCountry={setCountry}
          Countries={Countries}
          loading={loading}
          checker={checker}
          setChecker={setChecker}
          truncateString={truncateString}
        />
      </div>
    );
  }

  return (
    <span
      style={{
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
        justifyContent: fullwidth ? "space-between" : "",
        gap: "1rem",

        alignItems: "center",
      }}
    >
      <div
        className="unilistinputdiv d-flex"
        ref={unilistinputdivRef}
        onClick={handleUniClick}
        style={{
          cursor: "pointer",
          width: fullwidth ? "100%" : "",
          maxWidth: fullwidth ? "100%" : "",
        }}
      >
        <div className="uniselect">
          {selectedUni && selectedUni.logo && ValidateLogo(selectedUni.logo) ? (
            <img
              src={`${selectedUni.logo}`}
              onError={(e) =>
                (e.target.src =
                  "https://varyance.b-cdn.net/uploads/uniicon.png")
              }
              className="rounded-3"
              alt={"https://varyance.b-cdn.net/uploads/uniicon.png"}
              style={{
                objectFit: "contain",
                maxWidth: isNonMobile1440 ? "24px" : "24px",
                maxHeight: isNonMobile1440 ? "24px" : "24px",
              }}
            />
          ) : (
            <div
              className={`rounded-5 center align-items-center ${
                isNonMobile1440 ? "" : ""
              } notselecteduni `}
              style={{
                height: isNonMobile1440 ? "24px" : "24px",
                width: isNonMobile1440 ? "24px" : "24px",
                backgroundColor: country ? "#FFF" : "",
              }}
            >
              {" "}
              {country && country.icon ? (
                <img
                  src={country.icon}
                  alt={"https://varyance.b-cdn.net/uploads/uniicon.png"}
                  className="rounded-3"
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    height: "24px",
                    width: "24px",
                  }}
                />
              ) : (
                <SchoolIcon
                  style={{
                    color: "white",
                    height: "20px",
                    width: "20px",
                  }}
                />
              )}
            </div>
          )}

          <p className="gray90014400" style={{ fontWeight: 500 }}>
            {selectedUni
              ? isNonMobile1024
                ? truncateString(`${selectedUni.name}`, 35)
                : truncateString(selectedUni.name, 30)
              : "Select your university to register"}
          </p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#1D2939"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {showUni && (
        <div
          ref={unilistinputdivRef}
          style={{
            position: "absolute",
            overflow: "hidden",
            top: "0",
            left: 0,
            zIndex: 10,
            width: fullwidth ? "100%" : "",
          }}
          className="showUnilistmodal "
        >
          <div className="paddingsinsearchlist">
            <div className="searchinput">
              <div style={{ minHeight: "20px", minWidth: "20px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
                    stroke="#475467"
                    strokeWidth="1.70603"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search"
                className="searchinputuni"
              />
            </div>
            <hr style={{ width: "97%" }} />
            <div
              className=""
              style={{ overflow: "auto", maxHeight: "20.438rem" }}
            >
              {changeLocation && !checker ? (
                countrySearchList.map((con, index) => (
                  <div
                    key={index} // Make sure to add a unique key when mapping
                    className="d-flex unilists "
                    style={{
                      gap: "0.5rem",
                      marginTop: "1.25rem",
                    }}
                    onClick={() => {
                      setCountry(con);
                      ShowChangeLocation(false);
                      setSearch(""); // Assuming 'con' is the selected university in this context
                    }}
                  >
                    <img
                      src={con.icon}
                      alt="uni logo"
                      height="24px"
                      width="24px"
                      className="Interest_img_icon rounded-5"
                    />
                    <p
                      className="uniselecttext d-flex"
                      style={{ alignItems: "center" }}
                    >
                      {con.name}
                    </p>
                  </div>
                ))
              ) : (
                <>
                  {loading ? (
                    <div
                      className="d-flex gap-3"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1rem",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        Fetching Universities
                      </p>
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <>
                      {/* {quickselects.length > 0 && (
                        <div>
                          <p className="gray40014500">QUICKSELECT</p>
                          <div className="d-flex flex-column gap-4 py-3">
                            {quickselects.map((uni, index) => (
                              <div
                                key={index}
                                className="d-flex unilists"
                                style={{
                                  gap: "0.5rem",
                                  width: "97%",
                                  alignItems: "center",
                                  marginLeft: "0.5rem",
                                }}
                                onClick={() => {
                                  setSelectedUni(uni);
                                  setShowUni(false);
                                  dispatch(setUni({ Uni: uni }));
                                }}
                              >
                                <img
                                  src={
                                    uni.logo && ValidateLogo(uni.logo)
                                      ? uni.logo
                                      : uniicon
                                  }
                                  alt={uni.name || "University Logo"}
                                  className="Interest_img_icon shadowa rounded-5"
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    objectFit: "contain",
                                  }}
                                />
                                <p
                                  className="gray90014400 d-flex"
                                  style={{ fontWeight: 500 }}
                                >
                                  {truncateString(uni.name, 50)}
                                </p>
                              </div>
                            ))}
                          </div>
                          <p className="gray40014500 py-2">MORE UNIVERSITIES</p>
                        </div>
                      )} */}

                      {renderUniversityList()}
                    </>
                  )}
                </>
              )}
              <div
                className="d-flex unilists"
                style={{ gap: "0.5rem", marginTop: "1.25rem" }}
                onClick={() => {
                  setSelectedUni({ name: "other" });
                  navigate("/register?other=true");

                  setShowUni(false);
                }}
              >
                <p
                  className="gray90014400 d-flex"
                  style={{ alignItems: "center" }}
                >
                  other
                </p>
              </div>
            </div>
            <hr style={{ width: "97%" }} />
          </div>
          <div className="bottomsearchlist">
            <div
              className="d-flex"
              style={{
                justifyContent: "space-between",
                // width: "85%",
              }}
            >
              <div className="countrydisplay">
                {country ? (
                  <>
                    <img
                      src={country.icon}
                      alt="country flag"
                      width="16px"
                      height="16px"
                    />
                    {country.name}
                  </>
                ) : (
                  "select country"
                )}
              </div>
              <button
                className="changelocationbutton btn p-0"
                onClick={() => {
                  ShowChangeLocation(true);
                  setChecker(false);
                }}
              >
                {" "}
                Change Location
              </button>
            </div>
          </div>
        </div>
      )}
      {button && (
        <div
          className="d-flex"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <button
            className={`continuebutton ${pulsate ? "pulsingButton" : ""}`}
            style={{
              height: "50px",

              position: "relative",
              maxHeight: isNonMobile1440 ? "" : "48px",
              border: "none",
              width: isNonMobile450 ? "100%" : "90vw",
            }}
            onClick={(e) => {
              e.preventDefault();
              if (!noclicks) {
                navigate("/register");
              }
            }}
          >
            Join now
          </button>
        </div>
      )}
    </span>
  );
};

export default UniSelect;
