import "./header.css";
import Law from "../../assets/images/icons/Law.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useNavigate } from "react-router-dom";
const NewHeader = ({ selected, setSelected }) => {
  const navigate = useNavigate();
  const buttons = [
    { name: "Students", link: "/" },

    { name: "Employer-Hub", link: "/employer" },

    { name: "Career Centers", link: "/career-centers" },

    { name: "Student Organisations", link: "/student-organisations" },
  ];

  const handleContinueClick = () => {
    navigate("/register", {
      state: {
        selectedItems: [
          {
            id: "Corporate Law",
            icon: Law,
            text: "Corporate Law",
            select: false,
          },
        ],
      },
    });
  };

  return (
    <div>
      <div className="" style={{ background: "#F2F3F7" }}>
        <div className="marginsleftrightCompany">
          <div className="center">
            <div className="headerCompany space align-items-center">
              <div className="d-flex ">
                {buttons.map((btn, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                    }}
                  >
                    <a href={btn.link} style={{ textDecoration: "none" }}>
                      <button
                        className={`new-header-button mt-0 ${
                          selected === btn.name ? "selectedbuttonheader" : ""
                        }`}
                        onClick={() => {
                          setSelected(btn.name);
                        }}
                      >
                        {btn.name}
                      </button>
                    </a>

                    {/* {selected === btn.name && (
                      <div
                        style={{
                          position: "absolute",
                          background: "#FFF",
                          bottom: "-1px",
                          height: "1px",
                          width: "100%",
                          display: "block",
                        }}
                      ></div>
                    )} */}
                  </div>
                ))}
              </div>

              <div
                className="d-flex align-items-center new-header-button hoverred p-0"
                style={{ gap: "0.75rem", cursor: "pointer" }}
                onClick={() => {
                  handleContinueClick();
                }}
              >
                <div
                  style={{
                    height: "8px",
                    width: "8px",
                    borderRadius: "50%",
                    background: "#5FDCB3",
                  }}
                />
                Corporate Law Onboarding
                {/* <img
                  src={Netherlands}
                  alt="netherlands"
                  style={{ height: "20px", width: "20px", borderRadius: "50%" }}
                /> */}
                <ArrowForwardIosIcon
                  style={{ height: "20px", width: "20px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
