import { Link } from "react-router-dom";
import SelectedBox from "../../assets/images/icons/selectedbox";
import NonMobile from "../../components/NonMobile/NonMobile";
const Second = ({ packages }) => {
  const { isNonMobile1024, isNonMobile1440, isNonMobile500 } = NonMobile();

  return (
    <div className="marginsleftrightCompany" style={{ marginTop: "3.75rem" }}>
      <div className="center">
        <div
          className="headerCompany d-flex space"
          style={{
            position: "relative",
            flexDirection: isNonMobile1024 ? "row" : "column",
            gap: isNonMobile1440 ? "2rem" : "1.5rem",
          }}
        >
          {packages.map((item, index) => (
            <div
              className="space flex-column "
              key={index}
              style={{
                maxWidth: isNonMobile1440
                  ? "calc(33% - 21.33333px)"
                  : isNonMobile1024
                  ? "calc(33% - 16px)"
                  : "100%",
                gap: isNonMobile1024 ? "1rem" : "2rem",
                width: "100%",
                height: isNonMobile1024 ? "660px" : "fit-content",
                padding: isNonMobile1440 ? "24px" : "20px",
                border: "1px solid #EAECF0",
                borderRadius: "10px",
              }}
            >
              <div>
                <img
                  src={item.logo}
                  alt="logo"
                  style={{ height: "36px", width: "36px" }}
                  className="shadowa rounded-1"
                />
                <h4 style={{ margin: "1.875rem 0rem" }}>{item.title}</h4>
                <p className="gray90018500">{item.desc1}</p>
                <p
                  className="gray50014400"
                  style={{
                    margin: "1.875rem 0rem",
                    fontSize: "18px",
                    lineHeight: "120%",
                  }}
                >
                  {item.desc2}
                </p>
                <div className="d-flex flex-column gap-3">
                  {item.desc3.map((desc, index) => (
                    <div className="d-flex gap-1 " key={desc}>
                      <div
                        style={{
                          minHeight: "20px",
                          minWidth: "20px",
                          position: "relative",
                          marginTop: "-2px",
                        }}
                      >
                        <SelectedBox color={"#667085"} />
                      </div>
                      <p
                        className="gray70016400"
                        style={{ fontSize: "16px", lineHeight: "120%" }}
                      >
                        {desc}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex">
                  <Link to={item.buttonlink} style={{ textDecoration: "none" }}>
                    <button
                      className="continuebutton"
                      style={{
                        background: isNonMobile1024 ? "none" : "",
                        border: isNonMobile1024 ? "1px solid #E31b54" : "",
                        color: isNonMobile1024 ? "#E31b54" : "",
                        width: isNonMobile500 ? "" : "100%",
                      }}
                    >
                      {item.buttonname}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Second;
