import { Link } from "react-router-dom";
import NonMobile from "../../components/NonMobile/NonMobile";
const First = () => {
  const { isNonMobile1024, isNonMobile1440, isNonMobile768, isNonMobile500 } =
    NonMobile();
  return (
    <div style={{ marginTop: "6.75rem" }}>
      <div className="marginsleftrightCompany">
        <div className="center">
          <div className="headerCompany" style={{ position: "relative" }}>
            <p
              className="gray95024400"
              style={{
                fontWeight: 500,
                fontFamily: "Poppins",
                fontSize: isNonMobile1440
                  ? "1.5rem"
                  : isNonMobile768
                  ? "1.25rem"
                  : "1rem",
                lineHeight: isNonMobile768 ? "32px" : "24px",
              }}
            >
              Plans
            </p>
            <h1 style={{ margin: "1.5rem 0rem", maxWidth: "754px" }}>
              Pick the plan that fits your early talent strategy and budget.
            </h1>
            <div
              className="d-flex flex-wrap gap-4"
              style={{ flexDirection: isNonMobile500 ? "row" : "column" }}
            >
              <Link to={"/contact"} style={{ textDecoration: "none" }}>
                {" "}
                <button
                  className="continuebutton"
                  style={{ width: isNonMobile500 ? "" : "100%" }}
                >
                  Get a demo
                </button>
              </Link>
              <Link
                to={"/employer/register"}
                style={{ textDecoration: "none" }}
              >
                {" "}
                <button
                  className="backbutton"
                  style={{ width: isNonMobile500 ? "" : "100%" }}
                >
                  Start Onboarding (Free)
                </button>
              </Link>
            </div>
            <p className="mt-3 gray50014400" style={{ fontWeight: 500 }}>
              Get a demo of our solutions, or get started with free tools.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
