import NewComp from "../Home/NewComp";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import "./main.css";
import NonMobile from "../../components/NonMobile/NonMobile";
import HomeFooter from "../../components/Home/HomeFooter";
const CompanyPricing = () => {
  const { isNonMobile1024 } = NonMobile();
  const packages = [
    {
      logo: "https://varyance.b-cdn.net/uploads/Magazine261157738%20(1).png",
      title: "Brand",
      desc1: "Showcase your employer brand and attract top early talent.",
      desc2: "Includes",
      desc3: [
        "Promote your employer brand with up to 2 Insights Challenges",
        "Publish unlimited job posts and recruiting events",
        "Company Brand Page with social feed",
        "Single user licence with 3 workspaces",
      ],
      desc4: "Get your employer brand in front of early talent.",
      buttonlink: "/employer/register",
      buttonname: "Start Trial",
    },
    {
      logo: "https://varyance.b-cdn.net/uploads/megazine.png",
      title: "Engage",
      desc1:
        "Engage and connect with early talent using advanced engagement tools.",
      desc2: "Eveything in Basic, plus",
      desc3: [
        <>
          Design up to six{" "}
          <span className="gray70016400" style={{ fontWeight: 500 }}>
            Virtual Experience & Fast-Track Challenges
          </span>
        </>,
        "Build your early talent pipeline with on-platform events and fast-track job applications",

        "Access to your talent pool and student engagement analytics",
        "Multi-location license with up to 6 workspaces",
      ],
      desc4: "Connect with early talent using advanced engagement tools.",
      buttonlink: "/contact",
      buttonname: "Talk to Sales",
    },
    {
      logo: "https://varyance.b-cdn.net/uploads/logo.png",
      title: "Scale",
      desc1: "Scale your early talent strategy with our full suite.",
      desc2:
        "Everything we can do to empower you to create an awesome talent journey.",
      desc3: [
        <>
          Design{" "}
          <span className="gray70016400" style={{ fontWeight: 500 }}>
            unlimited Virtual Experiences
          </span>{" "}
          & up to <span className="gray70016400">4 Fast-Track Challenges</span>
        </>,
        "Access to asynchronous AI evaluated interviews (AAI)",

        "AI Smart Reply with custom knowledge base",
        "Multi-location license with  location based candidate tracking and up to 12 workspaces",
      ],
      desc4: "Scale your early talent strategy with our full suite.",
      buttonlink: "/contact",
      buttonname: "Talk to Sales",
    },
  ];
  return (
    <div>
      <NewComp>
        <div style={{ paddingBottom: "5rem" }}>
          <First />
          <Second packages={packages} />
        </div>
      </NewComp>
      {isNonMobile1024 && <Third packages={packages} />}
      <div className="" style={{ background: "#0C111D" }}>
        <div className="marginsleftrightCompany ">
          <div className="center">
            <div className="headerCompany">
              <HomeFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyPricing;
