import UpDownMarquee from "./UpDownMarque";
import SelectedGreen from "../../assets/images/icons/SelectedGreen";

import NonMobile from "../../components/NonMobile/NonMobile";

import linkedin from "../../assets/images/icons/linkedin.png";
import UniSelect from "./UniSelect";
const MarqueSection = ({ selectedUni, setSelectedUni }) => {
  const images1 = [
    "https://varyance.b-cdn.net/uploads/row11.png",
    "https://varyance.b-cdn.net/uploads/row12.png",
    "https://varyance.b-cdn.net/uploads/row13.png",
    "https://varyance.b-cdn.net/uploads/row14.png",
  ];
  const images2 = [
    "https://varyance.b-cdn.net/uploads/row21.png",
    "https://varyance.b-cdn.net/uploads/row22.png",
    "https://varyance.b-cdn.net/uploads/row23.png",
    "https://varyance.b-cdn.net/uploads/row24.png",
  ];
  const { isNonMobile768, isNonMobile500, isNonMobile1440 } = NonMobile();

  return (
    <div>
      <UpDownMarquee images1={images1} images2={images2}>
        <div>
          <p
            className="filmo30400"
            style={{ fontSize: "clamp(24px,4vw,30px)" }}
          >
            Start onboarding
          </p>
          <p
            className="gray60016400"
            style={{ marginTop: "0.75rem", fontSize: "clamp(14px,4vw,16px)" }}
          >
            Forget the traditional job search. Explore real-world cases and get{" "}
            <span
              className="gray60016400"
              style={{ fontWeight: "500", fontSize: "clamp(14px,4vw,16px)" }}
            >
              real insights
            </span>{" "}
            that put you ahead of the competition.
          </p>
          <div style={{ marginTop: "2rem" }}>
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.75rem" }}
            >
              <SelectedGreen />
              <p className="gray95014400 ">
                Expected Duration:{" "}
                <span className="gray95014400" style={{ fontWeight: 500 }}>
                  2-3min
                </span>
              </p>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.75rem", marginTop: "0.75rem" }}
            >
              <SelectedGreen />
              <div className="d-flex gap-2 align-items-center">
                <p className="gray95014400 ">
                  Requirements:{" "}
                  <span className="gray95014400" style={{ fontWeight: 500 }}>
                    University Email
                  </span>
                </p>
                <div
                  style={{
                    width: "1px",
                    height: "12px",
                    background: "#D0D5DD",
                  }}
                />
                <img
                  src={"https://varyance.b-cdn.net/uploads/google%20(2).png"}
                  alt="google"
                  style={{ height: "20px", width: "20px" }}
                />
                <div
                  style={{
                    width: "1px",
                    height: "12px",
                    background: "#D0D5DD",
                  }}
                />

                <img
                  src={linkedin}
                  alt="google"
                  style={{ height: "20px", width: "20px" }}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "3rem" }}>
            <div
              className="d-flex  align-items-center gap-3 "
              style={{ flexWrap: isNonMobile768 ? "nowrap" : "wrap" }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <UniSelect
                  button={isNonMobile1440 ? false : true}
                  setSelectedUni={setSelectedUni}
                  selectedUni={selectedUni}
                  fullwidth={isNonMobile1440 ? true : false}
                />
              </div>
              {isNonMobile1440 && (
                <a href={`/register`} style={{ textDecoration: "none" }}>
                  <button
                    className="continuebutton"
                    style={{
                      height: "48px",
                      minWidth: "113px",
                      fontWeight: 500,
                      width: isNonMobile500 ? "" : "100%",
                    }}
                  >
                    Join Now
                  </button>
                </a>
              )}
            </div>
          </div>
        </div>
      </UpDownMarquee>
    </div>
  );
};

export default MarqueSection;
