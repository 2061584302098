import NonMobile from "../../components/NonMobile/NonMobile";

const UpDownMarquee = ({
  images1,
  images2,
  children,
  nomargins = false,
  noText = false,
  reverse,
}) => {
  const { isNonMobile1024 } = NonMobile();
  return (
    <div
      style={{
        padding: nomargins
          ? "0rem"
          : isNonMobile1024
          ? "3.75rem 0rem 130px 0px"
          : "3.75rem 0rem 60px 0px",
        background: "rgba(242, 244, 247, 0.80)",
        height: noText ? "100%" : "",
      }}
    >
      <div
        className={`${
          nomargins ? "" : "marginsleftrightCompany marginsStudent"
        }`}
        style={{ height: noText ? "100%" : "" }}
      >
        <div className="center" style={{ height: noText ? "100%" : "" }}>
          <div
            className="headerCompany d-flex"
            style={{
              background: "#FFF",
              flexDirection: isNonMobile1024
                ? nomargins
                  ? "column-reverse"
                  : reverse
                  ? "row-reverse"
                  : "row"
                : "column-reverse",
              borderRadius: "5px",
              // flexDirection: noText ? "column" : "",
              height: noText ? "100%" : "",
            }}
          >
            <div
              className=""
              style={{
                width: isNonMobile1024
                  ? nomargins
                    ? "100%"
                    : "43.709%"
                  : "100%",
                padding: nomargins
                  ? "0rem "
                  : isNonMobile1024
                  ? "0rem 2.5rem"
                  : "1.25rem 1.25rem",
                height: noText ? "100%" : "",
              }}
            >
              <div
                className="d-flex"
                style={{
                  width: "100%",
                  gap: "0.75rem",
                  height: noText ? "100%" : "460px",
                }}
              >
                <div style={{ width: "calc(50% - 0.375rem)", height: "100%" }}>
                  <div
                    className="marquee-container"
                    style={{ height: noText ? "100%" : "" }}
                  >
                    <div className="marquee">
                      {images1.map((item, index) => (
                        <img
                          src={item}
                          key={index}
                          alt={`img-${index}`}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "12px",
                            aspectRatio: "1",
                          }}
                        />
                      ))}
                      {images1.map((item, index) => (
                        <img
                          src={item}
                          key={index}
                          alt={`img-${index}`}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "12px",
                            aspectRatio: "1",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "calc(50% - 0.375rem)",
                    height: "100%",
                    justifyContent: "start",
                  }}
                >
                  <div
                    className="marquee-container"
                    style={{ height: noText ? "100%" : "" }}
                  >
                    <div className="marquee2">
                      {images2.map((item, index) => (
                        <img
                          src={item}
                          key={index}
                          alt={`img-${index}`}
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "12px",
                            aspectRatio: "1",
                          }}
                        />
                      ))}
                      {images2.map((item, index) => (
                        <img
                          src={item}
                          key={index}
                          alt={`img-${index}`}
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "12px",
                            aspectRatio: "1",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="center align-items-center "
              style={{
                width: isNonMobile1024
                  ? nomargins
                    ? "100%"
                    : "56.290%"
                  : "100%",
                padding: nomargins
                  ? "0rem"
                  : isNonMobile1024
                  ? "0rem 2.5rem"
                  : "3.75rem 1.25rem 1.25rem 1.25rem",
              }}
            >
              <div style={{ width: "100%" }}>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpDownMarquee;
