import React, { useEffect, useState, Suspense } from "react";
import NewHeader from "../CompanyHome/NewHeader";

import "./home.css";
import { useLocation } from "react-router-dom";
import NonMobile from "../../components/NonMobile/NonMobile";

const SecondHeader = React.lazy(() => import("../CompanyHome/NewHeaderSecond"));
const NewComp = ({ children }) => {
  const [selected, setSelected] = useState("Students");
  const [careermega, setCareerMega] = useState(false);
  const { isNonMobile1024 } = NonMobile();
  const location = useLocation();
  const [solution1, setSolution1] = useState(false);
  const path = location.pathname.toLowerCase();

  useEffect(() => {
    if (path === "/") {
      setSelected("Students");
    } else if (path.toLowerCase().includes("/employer")) {
      setSelected("Employer-Hub");
    } else if (path.toLowerCase().includes("/career-center")) {
      setSelected("Career Centers");
    } else if (path.toLowerCase().includes("/student-organisations")) {
      setSelected("Student Organisations");
    }
  }, [path]);
  const [selected2, setSelected2] = useState("");

  return (
    <div style={{ width: "100vw", overflowX: "clip", position: "relative" }}>
      {isNonMobile1024 && (
        <div
          onMouseEnter={() => {
            setSolution1(false);
            setCareerMega(false);
          }}
        >
          <NewHeader selected={selected} setSelected={setSelected} />
        </div>
      )}
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader" />
          </div>
        }
      >
        <SecondHeader
          selected={selected}
          setSelected={setSelected}
          solution1={solution1}
          setSolution1={setSolution1}
          path={path}
          selected2={selected2}
          setSelected2={setSelected2}
          careermega={careermega}
          setCareerMega={setCareerMega}
        />
      </Suspense>
      {children}
    </div>
  );
};
export default NewComp;
